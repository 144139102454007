.labelLogin {
    width: 100px; 
}
.labelFtpSetting {
    width: 70px;
    margin: 0 5px;
}
.list-group-item.ftpSettingBg {
    background-color: rgba(255,255,255,0.85);
}
.form-inline .form-control.formTextSmaller {
    width: 100px;
}
.form-inline .form-control.formTextBigger {
    width: 555px;
}
.photographers.form-check-label {
    font-size: 0.9em;
}
.flextop {
    align-items: start !important;
}